export function iOSUserAgent() {
  return (
    [
      "iPad Simulator",
      "iPhone Simulator",
      "iPod Simulator",
      "iPad",
      "iPhone",
      "iPod",
    ].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes("Mac") && "ontouchend" in document) ||
    (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream)
  );
}

export function detectWebview(userAgent) {
  const rules = [
    // if it says it's a webview, let's go with that
    "WebView",
    // iOS webview will be the same as safari but missing "Safari"
    "(iPhone|iPod|iPad)(?!.*Safari)",
    // Android Lollipop and Above: webview will be the same as native but it will contain "wv"
    // Android KitKat to Lollipop webview will put Version/X.X Chrome/{version}.0.0.0
    "Android.*(;\\s+wv|Version/\\d.\\d\\s+Chrome/\\d+(\\.0){3})",
    // old chrome android webview agent
    "Linux; U; Android",
    "FBAN|FBAV|Instagram|FB_IAB|FB4A|FBIOS|FBBV|FBDV|FBMD|FBSN|FBSV|FBSS|FBCR|FBID|FBLC|FBOP",
  ];

  const webviewRegExp = new RegExp("(" + rules.join("|") + ")", "ig");

  return !!userAgent.match(webviewRegExp);
}
